(function ($) {

  // resize header on content scroll
  var $pane = $('#content');
  $pane.on("scroll", function() {
    if ($pane.scrollTop() > 50) {
      $("header")
        .removeClass("large")
        .addClass("small");
    } else {
      $("header")
        .removeClass("small")
        .addClass("large");
    }
  });

  // show tooltip on button copy hover
  var $tooltip = $('.snippet-body .code-toolbar .toolbar .toolbar-item button');
  $tooltip.hover(function() {
    $('.snippet-body .copy-tooltip').addClass('appear');
  }, function() {
    $('.snippet-body .copy-tooltip').removeClass('appear');
  });
}(jQuery));

// Run in Postaman button implementation
(function (p,o,s,t,m,a,n) {
  !p[s] && (p[s] = function () { (p[t] || (p[t] = [])).push(arguments); });
  !o.getElementById(s+t) && o.getElementsByTagName("head")[0].appendChild((
    (n = o.createElement("script")),
    (n.id = s+t), (n.async = 1), (n.src = m), n
  ));
}(window, document, "_pm", "PostmanRunObject", "https://run.pstmn.io/button.js"));
